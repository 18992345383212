@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App.sidebar-open {
  position: relative;
  height: 100vh;
  left: 215px;
  background: var(--body-color);
  transition: var(--tran-04);
  max-width: 1145px;
}

.App.sidebar-closed {
  position: relative;
  height: 100vh;
  left: 215px;
  background: var(--body-color);
  transition: var(--tran-04);
}

.App.dark {
  background-color: var(--DarkMode-color);
  color: var(--DarkMode-text-color);
  transition: var(--tran-04);
}

:root {
  --body-color: #ffffff;
  --sidebar-color: #eeeeee;
  --primary-color: #46ff93;
  --primary-color-light: #b6b6b6;
  --toggle-color: #222222;
  --text-color: #000000;
  --DarkMode-color: #141414;
  --DarkMode-text-color: #ffffff;

  /* ==== Transition ==== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.4s ease;
  --tran-05: all 0.5s ease;
}

body {
  height: 100vh;
  transition: var(--tran-04);
  background-color: var(--body-color);
  color: var(--text-color);
}

body.dark {
  background-color: var(--DarkMode-color);
  color: var(--DarkMode-text-color);
}

body {
  height: 100vh;
  transition: var(--tran-02);

}
