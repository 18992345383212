/* Estilo base del body */
body {
    background: var(--new-body-color);
    color: var(--new-text-color);
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  /* Contenedor principal de Home */
  .new-home-container {
    padding: 40px;
    text-align: left;
    background: var(--new-body-color);
    color: var(--new-text-color);
    font-family: 'Courier New', Courier, monospace;
  }
  
  /* Título principal de Home */
  .new-home-container h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: var(--new-primary-color);
    text-align: center;
    background-color: #3a3b3c;
    border-radius: 5px;
    width: 1000px;
    margin-left: 3%;
  }
  
  /* Botones sociales */
  .new-social-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
  }
  
  .new-social-button {
    display: inline-block;
    border-radius: 5px;
    color: var(--new-text-color);
    font-size: 40px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    margin: 0 10px;
    transition: background 0.3s;
  }
  
  /* Sección de explicación técnica */
  .new-explanation-section {
    padding: 20px;
    margin-bottom: 40px;
  }
  
  .new-code-style {
    background-color: var(--new-code-background);
    color: var(--new-code-text-color);
    padding: 20px;
    border-radius: 10px;
    font-size: 16px;
    white-space: pre-wrap;
    line-height: 1.5;
  }
  
  .new-code-command {
    color: var(--new-command-color);
  }
  
  .new-code-text {
    color: var(--new-code-highlight-color);
  }
  
  /* Preguntas Frecuentes */
  .new-faq-section {
    margin-top: 10px;
    padding: 10px;
  }
  
  .new-faq-section h2 {
    font-size: 28px;
    color: var(--new-primary-color);
    margin-bottom: 20px;
  }
  
  /* FAQ dentro de cards */
  .new-faq-container {
    width: 100%;
  }
  
  .new-faq-item {
    margin-bottom: 10px;
    background-color: var(--new-faq-background);
    padding: 10px;
    border-radius: 8px;
    color: var(--new-text-color);
  }
  
  .new-faq-item summary {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: var(--new-faq-question-color);
  }
  
  .new-faq-item p {
    margin-top: 5px;
    font-size: 16px;
    color: var(--new-text-color);
  }
  
  /* Modo claro */
  body.light {
    --new-body-color: #ffffff;
    --new-text-color: #000000;
    --new-primary-color: aqua;
    --new-code-background: #f0f0f0;
    --new-code-text-color: #000000;
    --new-command-color: #4e4e4e;
    --new-code-highlight-color: #007acc;
    --new-faq-background: #ffffff;
    --new-faq-question-color: #000000;
  }
  
  /* Modo oscuro */
  body.dark {
    --new-body-color: #000000;
    --new-text-color: #ffffff;
    --new-primary-color: #38ffe4;
    --new-code-background: #1e1e1e;
    --new-code-text-color: #38ffe4;
    --new-command-color: #38ffe4;
    --new-code-highlight-color: #00ffff;
    --new-faq-background: #1e1e1e;
    --new-faq-question-color: #38ffe4;
  }
  