.sidebar.dark {
  --body-color: #000000;
  --sidebar-color: #000000;
  --primary-color: #3a3b3c;
  --primary-color-light: #3a3b3c;
  --toggle-color: #ffffff;
  --text-color: #ffffff;
}

/* ----------- Reusable CSS ----------- */
.sidebar .text {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  transition: var(--tran-04);
  white-space: nowrap;
  opacity: 1;
}

.sidebar .image {
  min-width: 50px;
  display: flex;
  align-items: center;
}

/* ----------- SideBar ----------- */

.sidebar {
  position: fixed;
  top: 40px;
  left: 0;
  bottom: 10px;
  height: calc(100% - 50px);
  width: 215px;
  padding: 10px 14px;
  background: var(--sidebar-color);
  transition: var(--tran-04);
  border-radius: 10px; /* Mantén el border-radius aquí */
  margin-left: 1%;
  overflow-y: auto; /* Activa el scroll solo si es necesario */
  scrollbar-width: thin; /* Hace el scrollbar más delgado (Firefox) */
}

/* Ocultar las flechas del scrollbar en navegadores WebKit (Chrome, Safari) */
.sidebar::-webkit-scrollbar-button {
  display: none; /* Elimina las flechas del scrollbar */
}

/* Estilo para el scrollbar en navegadores WebKit */
.sidebar::-webkit-scrollbar {
  width: 8px; /* Ancho del scrollbar */
}

/* Fondo del track del scrollbar */
.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color de la pista del scrollbar */
  border-radius: 10px; /* Bordes redondeados */
}

/* Estilo del thumb (barra que se desplaza) */
.sidebar::-webkit-scrollbar-thumb {
  background: #888; /* Color del scrollbar */
  border-radius: 10px; /* Bordes redondeados */
  border: 2px solid #f1f1f1; /* Borde para que la barra se vea más delgada */
}

/* Hover en el thumb */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color del scrollbar cuando se pasa el mouse */
}

/* Estilo para Firefox */
.sidebar {
  scrollbar-width: thin; /* Hace el scrollbar más delgado (Firefox) */
  scrollbar-color: #888 #f1f1f1; /* Color de la barra y pista (Firefox) */
}


.sidebar .menuBar {
  display: flex;
  flex-direction: column;
  height: auto; /* Ajusta la altura automáticamente */
  flex: 1; /* Expande el contenido para ocupar el espacio restante */
}

.sidebar .menuBar .bottomContent {
  margin-top: auto; /* Empuja el contenido de abajo hacia el fondo */
}




.sidebar li {
  height: 50px;
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
}

.sidebar li .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  font-size: 20px;
}

.sidebar li .icon,
.sidebar li .text {
  color: var(--text-color);
  transition: var(--tran-04);
}

.sidebar header {
  position: relative;
}

.sidebar .imageText img {
  width: 60px;
}

.sidebar header .imageText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

header .imageText .headerText {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.headerText .name {
  font-weight: 600;
}

.headerText .profession {
  margin-top: -2px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 22px;
  width: 22px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--sidebar-color);
  font-size: 22px;
  cursor: pointer;
}

.sidebar.dark header .toggle {
  color: var(--text-color);
}

.sidebar.close header .toggle {
  transform: translateY(-50%);
}

.sidebar .searchBox {
  background: var(--primary-color-light);
  border-radius: 6px;
}

.searchBox input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 6px;
  background: var(--primary-color-light);
}

.sidebar li a {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 6px;
  transition: var(--tran-04);
}

.sidebar li a:hover {
  background: var(--primary-color);
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--sidebar-color);
}

.sidebar.dark li a:hover .icon,
.sidebar.dark li a:hover .text {
  color: var(--text-color);
}

.sidebar .menuBar {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menuBar .mode {
  position: relative;
  border-radius: 6px;
  background: var(--primary-color-light);
}

.menuBar .mode .moonSun {
  height: 50px;
  width: 60px;
  display: flex;
  align-items: center;
}

.menuBar .mode i {
  position: absolute;
}

.menuBar .mode .toggleSwitch {
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 60px;
  cursor: pointer;
  border-radius: 6px;
  background-color: var(--primary-color-light);
}

.toggleSwitch .switch {
  position: relative;
  height: 22px;
  width: 44px;
  border-radius: 25px;
  background: var(--toggle-color);
}

.toggleSwitch .switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background: var(--sidebar-color);
  transition: var(--tran-03);
}

.sidebar.dark .toggleSwitch .switch::before {
  left: 24px;
}

.walletButton {
  background-color: #74ff8b;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: center; /* Cambiar a center para centrar horizontalmente el texto */
  display: flex; /* Usar Flexbox para centrar verticalmente */
  align-items: center; /* Centrar verticalmente */
  justify-content: center; /* Centrar horizontalmente */
}

.walletButton:hover {
  background-color: #68ffac;
}
/* SideBar.module.css */

/* Asegúrate de que los íconos no se salgan de su contenedor */
.icon {
  width: 38px; /* Ajusta el tamaño según sea necesario */
  height: 38px;
  object-fit: contain; /* Asegura que la imagen mantenga sus proporciones */
  margin-right: 10px; /* Espacio entre el ícono y el texto */
}
