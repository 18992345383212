.dao-container {
    padding: 40px;
    background-color: var(--body-color);
    color: var(--text-color);
    font-family: 'Courier New', Courier, monospace;
  }
  
  h1, h2 {
    color: var(--primary-color);
    text-align: center;
  }
  
  .proposals-section {
    margin-top: 40px;
  }
  
  .proposal-card {
    background-color: var(--sidebar-color);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .proposal-card h3 {
    margin-bottom: 10px;
    color: var(--primary-color-light);
  }
  
  .proposal-stats {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  .proposal-stats p {
    font-size: 1.1rem;
    color: var(--text-color-light);
  }
  
  .create-proposal-section {
    margin-top: 40px;
    text-align: center;
  }
  
  .create-proposal-btn {
    padding: 12px 24px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .create-proposal-btn:hover {
    background-color: var(--primary-color-light);
  }
  