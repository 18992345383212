/* .crypto-widgets-container */
.crypto-widgets-container {
  padding: 20px;
  text-align: center;
}

/* .crypto-widgets-grid */
.crypto-widgets-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr)); /* Ajusta el tamaño mínimo de las tarjetas */
  gap: 5px; /* Reduce el espacio entre las tarjetas */
}

/* .crypto-widget-card */
.crypto-widget-card {
  border: 1px solid var(--primary-color-light); /* Borde del card */
  border-radius: 8px;
  padding: 10px; /* Aumenta el padding para mejorar el espaciado interno */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  background-color: var(--card-bg); /* Añade un fondo con variable */
  max-width: 240px; /* Tamaño máximo del card */
  width: 100%; /* Asegura que el card ocupe el espacio disponible */
  box-sizing: border-box; /* Incluye padding y borde en el tamaño total */
  margin: 0; /* Elimina el margen superior */
  margin-top: 10%;
  margin-left: 10%;
}

/* Opcional: Añade un margen inferior a las tarjetas si es necesario */
.crypto-widget-card:not(:last-child) {
  margin-bottom: 5px; /* Espacio debajo de cada tarjeta, excepto la última */
}

.crypto-widget-card .crypto-icon {
  width: 40px; /* Tamaño del icono */
  height: 40px;
  margin-bottom: 10px;
}

.crypto-price {
  font-size: 1.1em; /* Tamaño de fuente */
  color: aqua;
  margin-bottom: 10px;
}

/* .crypto-trend */
.crypto-trend {
  height: 80px; /* Altura del trend */
  position: relative;
}

.crypto-trend svg {
  width: 100%;
  height: 100%;
}

.line {
  fill: none;
  stroke: var(--primary-color); /* Color de la línea según el tema */
  stroke-width: 2px;
}

/* .fee-calculator */
.fee-calculator {
  margin-top: 10px;
}

.fee-calculator input {
  padding: 5px;
  width: 80px; /* Tamaño del input */
  margin-bottom: 10px;
}

.fee-calculator p {
  font-size: 0.9em; /* Tamaño de fuente */
  color: rgb(0, 89, 255);
  margin-top: 5px;
}
