.charts-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columnas */
  gap: 20px;
  padding: 20px;
  margin-left: 2%;
  border-radius: 8px;
}

.card {
  background-color: var(--body-color); /* Fondo de las cards */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px; /* Reducir padding */
  text-align: center;
  color: var(--text-color);
  transition: var(--tran-04);
  border: 0.25px solid #636363; /* Borde negro en modo claro */
  width: 250px; /* Fijar el ancho de la card */
  height: 200px; /* Fijar la altura de la card */
  overflow: hidden; /* Evitar que el contenido desborde */
}

/* Modo oscuro */
body.dark .card {
  background-color: var(--DarkMode-color);
  color: var(--DarkMode-text-color);
  border: 1px solid #ffffff; /* Borde blanco en modo oscuro */
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.blockchain-icon {
  width: 35px; /* Reducir tamaño de los iconos */
  height: 35px;
  margin-right: 5px;
  margin-top: -5%;
}

.card h3 {
  font-size: 16px; /* Reducir el tamaño de la fuente */
  color: aqua;
}

.card p {
  font-size: 14px; /* Reducir el tamaño de la fuente */
  color: blueviolet;
  margin: 5px 0; /* Reducir margen entre párrafos */
}

.progress-bar {
  width: 100%;
  height: 8px; /* Reducir la altura de la barra de progreso */
  background-color: var(--primary-color-light);
  border-radius: 5px;
  margin-top: 8px; /* Reducir margen superior */
}

.progress {
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 5px;
}

/* Modo oscuro */
body.dark .card {
  background-color: var(--DarkMode-color);
  color: var(--DarkMode-text-color);
}

body.dark .progress-bar {
  background-color: #444;
}

body.dark .progress {
  background-color: var(--primary-color);
}
