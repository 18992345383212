/* Contenedor general para las tarjetas específicas de generación */
.generate-codes-container {
  display: grid;
  grid-template-columns: 1fr; /* Tarjeta de generar código ocupa una sola columna */
  gap: 20px;
  padding: 20px;
}

/* Estilos para la tarjeta principal de generación */
.generate-secret-card {
  background-color: var(--body-color);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 90%; /* Reducimos el ancho de la tarjeta */
  max-width: 990px; /* Ajustamos un máximo de anchura */
  margin: 0 auto; /* Centramos la tarjeta */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: auto;
  color: #363636;
}

/* Card para mostrar los códigos */
.code-display {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  color: #00ffff;
  word-break: break-all;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Estilo para los botones de copiar código */
.copy-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
}

.copy-button i {
  font-size: 18px;
  color: #00ffff;
}

.copy-button:hover i {
  color: var(--primary-color-light);
}

/* Botón de generar código */
.generate-secret-button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.generate-secret-button:hover {
  background-color: var(--primary-color-light);
}

/* Popup para notificación de éxito */
.popup-success {
  background-color: lightgreen;
  color: black;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.warning {
  color: yellow;
  font-size: 14px;
  margin-top: 10px;
  word-wrap: break-word;
}

/* Contenedor general para las otras tarjetas */
/* Contenedor para las 4 tarjetas */
.other-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columnas para las 4 tarjetas */
  gap: 20px;
  padding: 20px;
}
.card {
  background-color: var(--body-color);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 600px; /* Aumentamos el tamaño máximo */
  margin: 0 auto;
  height: auto;
  min-height: 300px; /* Aumentamos la altura mínima */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

/* Ajuste general para inputs dentro de las tarjetas */
.card input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 6px;
  border: 1px solid var(--primary-color-light);
  font-size: 14px;
}

.card button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.card button:hover {
  background-color: var(--primary-color-light);
}

.error {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

/* Mejora de diseño visual para inputs */
.card input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 5px var(--primary-color);
}

/* Ajuste de tarjetas para pantallas pequeñas */
@media (max-width: 768px) {
  .other-cards-container {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
  }
}
