/* General Home styles */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: var(--body-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hero Section */
.hero-section {
  text-align: center;
  margin-bottom: 40px;
}

.hero-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: var(--text-color-light);
}

/* Button */
.connect-btn {
  padding: 12px 24px;
  background-color: #ffa7a7;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.connect-btn:hover {
  background-color: var(--primary-color-light);
}

/* User Info Panel */
.user-info-panel {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

/* User Cards */
.user-card {
  background-color: var(--sidebar-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 250px;
  text-align: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.user-card h3 {
  margin-bottom: 10px;
  color: var(--text-color);
}

.user-card p {
  font-size: 1.1rem;
  color: var(--text-color-light);
}

.user-img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.network-img {
  width: 50px;
  height: 50px;
  margin-top: 10px;
}

/* Feature Section */
.features-section {
  margin-top: 60px;
  text-align: center;
}

.features-section h2 {
  margin-bottom: 30px;
  font-size: 1.8rem;
  color: var(--primary-color);
}

.feature-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.feature-card {
  background-color: var(--sidebar-color);
  padding: 20px;
  border-radius: 10px;
  width: 250px;
  text-align: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.feature-card h3 {
  margin-bottom: 10px;
  color: var(--text-color);
}

.feature-card p {
  font-size: 1rem;
  color: var(--text-color-light);
}

/* Footer */
footer {
  margin-top: 60px;
  font-size: 0.9rem;
  color: var(--text-color-light);
}

/* Dark Mode Colors */
body.dark {
  --body-color: #000000;
  --text-color: #ffffff;
  --text-color-light: #d1d1d1;
  --primary-color: #38ffe4;
  --primary-color-light: #2ecdbd;
  --sidebar-color: #1e1e1e;
}

/* Light Mode Colors */
body.light {
  --body-color: #ffffff;
  --text-color: #000000;
  --text-color-light: #4e4e4e;
  --primary-color: #007acc;
  --primary-color-light: #005bb5;
  --sidebar-color: #f4f4f4;
}
